import { default as classNames } from "classnames";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ModeToggle } from "../theme-mode-toggle";

import { Button } from "./button";
import { UserInfo } from "./user-nav";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAuth } from "@/providers/AuthContextProvider";
import { Route } from "@/routes/routes";
import { isPermitted } from "@/utils/permission-manager";

type Props = {
  collapsed: boolean;
  onToggle(collapsed: boolean): void;
};

const Sidebar = ({ collapsed, onToggle }: Props) => {
  const { userData } = useAuth();
  const Icon = collapsed ? ChevronRight : ChevronLeft;
  const location = useLocation();
  const [menuItems, setMenuItems] = useState<Route[]>([]);

  useEffect(() => {
    const importMenuItems = async () => {
      // dynamic import due to an issue
      const routesModule = await import("@/routes/routes");
      const fetchedMenuItems = routesModule.getMenuItems();

      const filteredMenuItems = fetchedMenuItems.filter((item) =>
        item.permission ? isPermitted(item.permission, userData) : true
      );

      setMenuItems(filteredMenuItems);
    };

    importMenuItems();
  }, []);

  return (
    <div
      className={classNames({
        "fixed md:static md:translate-x-0 z-20 bg-background": true,
        "transition-all duration-300 ease-in-out": true,
        "w-[300px]": !collapsed,
        "w-16": collapsed,
        "border-r border-border": true,
      })}
    >
      <div
        className={classNames({
          "flex flex-col justify-between h-screen md:h-full sticky inset-0 ":
            true,
        })}
      >
        <div
          className={classNames({
            "flex items-center border-b border-border transition-none": true,
            "p-4 justify-end": !collapsed,
            "py-4 justify-center": collapsed,
          })}
        >
          <Button
            className="grid w-10 h-10 opacity-0 place-content-center md:opacity-100"
            onClick={() => onToggle(!collapsed)}
            size={"icon"}
            variant={"ghost"}
          >
            <Icon className="w-5 h-5" />
          </Button>
        </div>
        <nav className="flex-grow">
          <ul
            className={classNames({
              "my-2 flex flex-col gap-2 items-stretch": true,
            })}
          >
            {menuItems.map((item, index) => {
              return (
                <li
                  className={classNames({
                    "flex transition-all duration-150 text-md border-b border-border rounded-md p-2 mx-3":
                      true,
                    "hover:bg-hover hover:font-semibold": true,
                    "font-normal": !location.pathname.startsWith(item.path),
                    "font-semibold bg-accent": location.pathname.startsWith(
                      item.path
                    ),
                    "gap-4": !collapsed,
                    "w-10 h-10": collapsed,
                  })}
                  key={index}
                >
                  <Link
                    className={classNames({
                      "w-full flex gap-2 items-center justify-start":
                        !collapsed,
                    })}
                    to={item.path}
                  >
                    <TooltipProvider delayDuration={200}>
                      <Tooltip {...(!collapsed ? { open: false } : {})}>
                        <TooltipTrigger>{item.icon}</TooltipTrigger>
                        <TooltipContent side="right">
                          {item.label}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <span className="text-primary">
                      {!collapsed && item.label}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
        <div
          className={classNames({
            flex: true,
            "justify-center": collapsed,
            "justify-between": !collapsed,
            "transition-all duration-300": true,
            "p-2 mx-3 gap-4 ": true,
          })}
        >
          <TooltipProvider delayDuration={200}>
            <Tooltip {...(!collapsed ? { open: false } : {})}>
              <TooltipTrigger>
                <UserInfo />
              </TooltipTrigger>
              <TooltipContent side="right">
                <span className="font-medium">
                  {userData?.attributes?.email}
                </span>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          {!collapsed && <ModeToggle />}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
