import { Amplify } from 'aws-amplify'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import './index.css'

import { awsConfig } from './configs/aws.config'
import {
  AuthProvider,
  DataPointsContextProvider,
  PartnershipContextProvider,
  PricingTierContextProvider,
  RoleAndPermissionContextProvider,
  TagsContextProvider,
  ThemeProvider
} from './providers'
import { OrganizationCleanupContextProvider } from './providers/OrganizationCleanupContextProvider'
import { routes } from './routes/routes'

import { Toaster } from '@/components/ui/toaster'

Amplify.configure(awsConfig)

const router = createBrowserRouter(routes)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
    <AuthProvider>
      <RoleAndPermissionContextProvider>
        <OrganizationCleanupContextProvider>
          <DataPointsContextProvider>
            <PricingTierContextProvider>
              <PartnershipContextProvider>
                <TagsContextProvider>
                  <RouterProvider router={router} />
                </TagsContextProvider>
              </PartnershipContextProvider>
            </PricingTierContextProvider>
          </DataPointsContextProvider>
        </OrganizationCleanupContextProvider>
      </RoleAndPermissionContextProvider>
    </AuthProvider>
    <Toaster />
  </ThemeProvider>
)
