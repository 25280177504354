import { config as URL_CONFIG } from '@/configs/api.config'

const config = {
  aws_project_region: process.env.REACT_APP_REGION || 'eu-central-1',
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_IDENTITY_POOL_ID ||
    'eu-central-1:4087a0df-6a20-4475-891b-8810159d1f2b',
  aws_cognito_region: process.env.REACT_APP_REGION || 'eu-central-1',
  aws_user_pools_id:
    process.env.REACT_APP_USER_POOL_ID || 'eu-central-1_6gsyzF6vQ',
  aws_user_pools_web_client_id:
    process.env.REACT_APP_CLIENT_ID || '4421cv4ktmpk6un4bl92i22fbf',
  oauth: {
    domain:
      process.env.REACT_APP_OAUTH_DOMAIN ||
      'adminpanelui80877a34-80877a34-dev.auth.eu-central-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn:
      process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN ||
      'https://adminportal.dev.epilot.cloud/, https://www.admin.dev.epilot.cloud/, http://localhost:3002/',
    redirectSignOut:
      process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT ||
      'https://adminportal.dev.epilot.cloud/, https://www.admin.dev.epilot.cloud/, http://localhost:3002/',
    responseType: 'code'
  },
  Auth: {
    identityPoolId:
      process.env.REACT_APP_IDENTITY_POOL_ID ||
      'eu-central-1:4087a0df-6a20-4475-891b-8810159d1f2b',
    region: process.env.REACT_APP_REGION || 'eu-central-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID || 'eu-central-1_6gsyzF6vQ',
    userPoolWebClientId:
      process.env.REACT_APP_CLIENT_ID || '4421cv4ktmpk6un4bl92i22fbf'
  }
}

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

// Assuming you have two redirect URIs, and the first is for localhost and second is for production

export const awsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: isLocalhost
      ? 'http://localhost:3002/'
      : config?.oauth?.redirectSignIn?.split(',')[0],
    redirectSignOut: isLocalhost
      ? 'http://localhost:3002/'
      : config?.oauth?.redirectSignOut?.split(',')[0]
  },
  API: {
    endpoints: [
      {
        name: 'internal-auth',
        endpoint: URL_CONFIG.API_INTERNAL_AUTH
      }
    ]
  },
  Auth: {
    ...config.Auth,
    authenticationFlowType: null
  }
}
