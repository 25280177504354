import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { isEqual } from 'lodash'
import { AlertCircle } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import BlueprintsList from '../blueprints/page'
import JourneyAnalytics from '../journey-analytics/page'
import PartnerList from '../partner/page'
import { PricingTierSettings } from '../pricing-tier/columns'
import RoleList from '../role/page'
import UsageMetrics from '../usage-metrics/page'
import UserList from '../user/page'

import { PricingTierComponents, config } from '@/api'
import OrganizationDataPoints from '@/components/organization-datapoints'
import { OrganizationDeleteDialog } from '@/components/organization-delete-dialog'
import OrganizationFeatureSettings, {
  OrganizationFeatures
} from '@/components/organization-feature-settings'
import OrganizationPartnership from '@/components/organization-partnership'
import PricingTierAdvancedSettings from '@/components/pricing-tier-settings'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { MultiSelectAutoComplete } from '@/components/ui/multi-select-autocomplete'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { toast } from '@/components/ui/use-toast'
import { IOrganization } from '@/models'
import { useAuth, useDataPoints, usePricingTiers, useTags } from '@/providers'
import { useOrganizationCleanup } from '@/providers/OrganizationCleanupContextProvider'
import {
  ORGANIZATION_QUERY_IDS,
  useMutationAssignPricingTier,
  useMutationUpdateOrganization,
  useMutationUpdateOrganizationFeatures,
  useMutationUpdatePricingTier,
  useMutationUpdateRole,
  useQueryGetActivePartnerByOrgId,
  useQueryGetAllRoles,
  useQueryGetOrgElasticCluster,
  useQueryGetOrganization,
  useQueryGetOrganizationSettings,
  useQueryGetPricingTierByOrganizationId,
  useQueryUsersByOrgId
} from '@/services'
import SlackService from '@/services/slack.service'
import { getGrantsForPricingTier } from '@/utils'
import { Permissions, isPermitted } from '@/utils/permission-manager'

const slackService = new SlackService(config.SLACK_WEBHOOK_URL)
const OrganizationDetail = () => {
  const queryClient = useQueryClient()
  const {
    organizationsToCleanup,
    isLoading: isLoadingOrganizationCleanupStatus
  } = useOrganizationCleanup()

  const { orgId } = useParams<{
    orgId: string
  }>()

  const { data: organization, isLoading: isOrganizationLoading } =
    useQueryGetOrganization(
      {
        orgId: orgId!
      },
      {
        enabled: Boolean(orgId)
      }
    )

  const {
    data: organizationSettings,
    isLoading: isLoadingOrganizationSettings
  } = useQueryGetOrganizationSettings(orgId!, {
    enabled: Boolean(orgId)
  })

  const {
    organizationTags: allTags,
    isOrganizationTagsLoading: isAllTagsLoading
  } = useTags()

  const {
    isLoading: isLoadingPricingTiers,
    pricingTierAssignments,
    pricingTiers
  } = usePricingTiers()

  const { userData } = useAuth()

  const { dataPoints, isLoading: isDataPointsLoading } = useDataPoints()

  const [pricingTierState, setPricingTierState] =
    useState<PricingTierComponents.Schemas.PricingTier>()

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [selectedTags, setSelectedTags] = useState<string[]>(
    organization?.tags ?? []
  )
  const [selectedPricingTierId, setSelectedPricingTierId] = useState<
    string | undefined
  >()

  const [organizationState, setOrganizationState] = useState<IOrganization>(
    {} as IOrganization
  )

  const [isUpdatingOrganization, setIsUpdatingOrganization] = useState(false)
  const [isUpdatingOrganizationFeatures, setIsUpdatingOrganizationFeatures] =
    useState(false)

  const dataPoint = dataPoints.find((x) => x.org_id.toString() === orgId)

  useEffect(() => {
    if (organization) {
      setSelectedTags(organization?.tags ?? [])
      setOrganizationState(organization)
    }
  }, [organization])

  const { data: assignedPricingTier, isLoading: isLoadingAssignedPricingTier } =
    useQueryGetPricingTierByOrganizationId(selectedPricingTierId!, orgId!, {
      enabled: Boolean(selectedPricingTierId) && Boolean(orgId)
    })

  useEffect(() => {
    if (assignedPricingTier) {
      handleResetPricingTierState(assignedPricingTier)
    }
  }, [assignedPricingTier])

  const handleResetPricingTierState = (
    pricingTier: PricingTierComponents.Schemas.PricingTier
  ) => {
    const pricingTierDefaultValues = Object.fromEntries(
      PricingTierSettings.map((field) => [
        field.key,
        pricingTier?.settings?.[field.key] ?? field.default
      ]).filter((x) => x[0] !== 'name')
    )

    setPricingTierState({
      ...pricingTier,
      settings: pricingTierDefaultValues
    })
  }

  useEffect(() => {
    if (pricingTierAssignments) {
      const pricingTierId = pricingTierAssignments.find(
        (x) => x.organization_id === orgId
      )?.pricing_tier_id

      setSelectedPricingTierId(pricingTierId)
    }
  }, [orgId, pricingTierAssignments])

  const getMaxBillableUsersLastMonth = () => {
    return (
      (dataPoint?.max_users_last_month ?? 0) -
      (dataPoint?.non_billable_users_last_month ?? 0)
    )
  }

  const getAdditionalPaidUsersLastMonth = () => {
    // if pricing tier setting is overriden use it, otherwise use default pricing tier setting
    const freeUserLimit = pricingTierState?.override_settings?.free_user
      ? pricingTierState?.override_settings?.free_user?.quota
      : pricingTierState?.settings?.free_user?.quota || 0

    // if there's a freeUserLimit set and they have more users last month than freeUserLimit
    if (+freeUserLimit > 0 && getMaxBillableUsersLastMonth() > +freeUserLimit) {
      return getMaxBillableUsersLastMonth() - +freeUserLimit
    }

    return 0
  }

  const { data: activePartners, isLoading: isActivePartnersLoading } =
    useQueryGetActivePartnerByOrgId(orgId!, {
      enabled: Boolean(orgId)
    })

  const { data: endCustomerPortalUsers } = useQueryUsersByOrgId(
    orgId!,
    'END_CUSTOMER_PORTAL',
    {
      enabled: Boolean(orgId)
    }
  )

  const { data: installerPortalUsers } = useQueryUsersByOrgId(
    orgId!,
    'INSTALLER_PORTAL',
    {
      enabled: Boolean(orgId)
    }
  )

  const getActualPartnerUsers = () => {
    let actualPartnerUsers = 0

    if (!isDataPointsLoading && !isActivePartnersLoading) {
      for (const activePartner of activePartners?.data.results ?? []) {
        actualPartnerUsers +=
          dataPoints?.find(
            (dp) => dp.org_id.toString() === activePartner?.partner_org_id
          )?.actual_users ?? 0
      }
    }

    return actualPartnerUsers
  }

  const getMaxPartnerUsersLastMonth = () => {
    let maxPartnerUsers = 0

    if (!isDataPointsLoading && !isActivePartnersLoading) {
      for (const activePartner of activePartners?.data.results ?? []) {
        maxPartnerUsers +=
          dataPoints?.find(
            (dp) => dp.org_id.toString() === activePartner?.partner_org_id
          )?.max_users_last_month ?? 0
      }
    }

    return maxPartnerUsers
  }

  const { data: organizationRoles } = useQueryGetAllRoles(
    { orgId: orgId! },
    {
      enabled: Boolean(orgId)
    }
  )

  const rootRole = organizationRoles?.data.roles?.find((x) => x.slug === 'root')

  const elasticClusterForOrg = useQueryGetOrgElasticCluster(orgId!).data?.data
    ?.cluster

  const updateOrganization = useMutationUpdateOrganization()
  const updateOrganizationFeatures = useMutationUpdateOrganizationFeatures(
    orgId!
  )
  const updateOrganizationPricingTier = useMutationUpdatePricingTier(orgId)
  const assignPricingTier = useMutationAssignPricingTier()
  const updateRole = useMutationUpdateRole(rootRole?.id ?? '', orgId!)

  const handleUpdateOrganization = () => {
    setIsUpdatingOrganization(true)
    if (pricingTierState?.id) {
      assignPricingTier.mutate(
        {
          organization_id: orgId,
          pricing_tier_id: pricingTierState?.id
        },
        {
          onError: (err) => {
            toast({
              title: 'Error',
              description: `Error while assigning pricing tier. ${err?.['message']}`
            })
          }
        }
      )

      updateOrganizationPricingTier.mutate(
        { ...pricingTierState, updated_by: userData?.attributes?.email || '' },
        {
          onError: (err) => {
            toast({
              title: 'Error',
              description: `Error while updating pricing tier. ${err?.['message']}`
            })
          }
        }
      )
    }

    const pricingTierGrants = getGrantsForPricingTier(pricingTierState!)
    const rootRoleGrants = (rootRole?.grants || []).filter((grant) => {
      return (
        !pricingTierState?.settings?.[grant.action.split(':')[0]] &&
        !pricingTierState?.override_settings?.[grant.action.split(':')[0]] &&
        !pricingTierGrants.find((x) => x.action === grant.action)
      )
    })

    const grants = [...pricingTierGrants, ...rootRoleGrants]

    updateRole.mutate({
      grants: grants,
      name: rootRole!.name,
      slug: rootRole!.slug!,
      id: rootRole!.id!,
      organization_id: rootRole!.organization_id!,
      type: rootRole!.type!
    })

    updateOrganization.mutate(
      {
        ...organizationState,
        tags: selectedTags,
        company_size: organizationState.company_size,
        pricing_tier_id: pricingTierState?.id
      },
      {
        onSuccess: () => {
          setIsUpdatingOrganization(false)
          toast({
            title: 'Success',
            description: 'Organization updated successfully'
          })
        },
        onError: (err) => {
          setIsUpdatingOrganization(false)
          toast({
            title: 'Error',
            description: `Error while updating organization. ${err?.['message']}`
          })
        }
      }
    )
  }

  const isDisabledToUpdate = Boolean(
    organizationsToCleanup?.some((x) => x.org_id === orgId) ||
      isLoadingOrganizationCleanupStatus
  )

  return (
    <Tabs className="w-full" defaultValue="settings">
      <TabsList className="grid w-full grid-cols-8">
        <TabsTrigger value="settings">Settings</TabsTrigger>
        <TabsTrigger value="features">Features</TabsTrigger>
        <TabsTrigger value="blueprints">Blueprints</TabsTrigger>
        <TabsTrigger value="users">Users</TabsTrigger>
        <TabsTrigger value="roles">Roles</TabsTrigger>
        <TabsTrigger value="partners">Active Partners</TabsTrigger>
        <TabsTrigger value="journey_analytics">Journey Analytics</TabsTrigger>
        <TabsTrigger value="usage-metrics">Usage Metrics</TabsTrigger>
      </TabsList>
      {isDisabledToUpdate && !isLoadingOrganizationCleanupStatus && (
        <Alert className="mt-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Warning</AlertTitle>
          <AlertDescription>
            This organization is marked for cleanup. You can not update any data
            on this organization.
          </AlertDescription>
        </Alert>
      )}
      <TabsContent value="settings">
        <div className="space-y-4">
          <div className="flex space-x-12">
            <div className="w-1/2 space-y-4">
              <div className="space-y-2">
                <Label htmlFor="id">ID</Label>
                <Input disabled id="id" value={organization?.id} />
              </div>
              <div className="space-y-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  disabled={isDisabledToUpdate}
                  id="name"
                  onChange={(e) =>
                    setOrganizationState((prev) => ({
                      ...prev,
                      name: e.target.value
                    }))
                  }
                  value={organizationState?.name ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="customer_number">Customer Number</Label>
                <Input
                  disabled={isDisabledToUpdate}
                  id="customer_number"
                  onChange={(e) =>
                    setOrganizationState((prev) => ({
                      ...prev,
                      customer_number: e.target.value
                    }))
                  }
                  value={organizationState?.customer_number ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  disabled={isDisabledToUpdate}
                  id="name"
                  onChange={(e) =>
                    setOrganizationState((prev) => ({
                      ...prev,
                      email: e.target.value
                    }))
                  }
                  type="email"
                  value={organizationState?.email ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="symbol">Symbol</Label>
                <Input
                  disabled
                  id="symbol"
                  value={organization?.symbol ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="type">Type</Label>
                <Input disabled id="type" value={organization?.type} />
              </div>
              <div className="space-y-2">
                <Label htmlFor="status">Status</Label>
                <Input
                  disabled
                  id="status"
                  value={organization?.status ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label>Severity Group</Label>
                <Select
                  disabled={isDisabledToUpdate}
                  onValueChange={(e) =>
                    setOrganizationState((prev) => ({
                      ...prev,
                      severity_group: e
                    }))
                  }
                  value={organizationState.severity_group}
                >
                  <SelectTrigger
                    className={classNames({
                      'text-primary': organizationState.severity_group,
                      'text-placeholder': !organizationState.severity_group
                    })}
                  >
                    <SelectValue placeholder="Select a Severity Group" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={'Low'}>Low</SelectItem>
                    <SelectItem value={'Medium'}>Medium</SelectItem>
                    <SelectItem value={'High'}>High</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="created_date">Created At</Label>
                <Input
                  disabled
                  id="created_date"
                  value={
                    new Date(
                      organization?.created_date as string
                    )?.toLocaleString() ?? ''
                  }
                />
              </div>
              {!isDisabledToUpdate && (
                <div className="w-full flex justify-between">
                  <Button
                    loading={isUpdatingOrganization}
                    onClick={() => handleUpdateOrganization()}
                  >
                    Update Organization
                  </Button>

                  <Button
                    disabled={!isPermitted(Permissions.DELETE_ORG, userData)}
                    loading={isUpdatingOrganization}
                    onClick={() => setShowDeleteDialog(true)}
                    variant={'destructive'}
                  >
                    Delete Organization
                  </Button>
                </div>
              )}
            </div>
            <div className="w-1/2 space-y-4">
              <div className="space-y-2">
                <Label>Tags</Label>
                <MultiSelectAutoComplete
                  allowNewValues
                  disabled={isDisabledToUpdate}
                  isLoading={isAllTagsLoading}
                  items={allTags || []}
                  onNewItem={(item) => item}
                  onSelect={(item) =>
                    setSelectedTags((prev) => [...prev, item as string])
                  }
                  onUnselect={(item) =>
                    setSelectedTags((prevItems) =>
                      prevItems.filter(
                        (selectedItem) => selectedItem !== (item as string)
                      )
                    )
                  }
                  placeholder="Choose the tags to assign to the organization"
                  selectedItems={selectedTags}
                />
              </div>

              <div className="space-y-2">
                <Label>Company Size</Label>
                <Select
                  disabled={isDisabledToUpdate}
                  onValueChange={(e) =>
                    setOrganizationState((prev) => ({
                      ...prev,
                      company_size: e
                    }))
                  }
                  value={organizationState.company_size}
                >
                  <SelectTrigger
                    className={classNames({
                      'text-primary': organizationState.company_size,
                      'text-placeholder': !organizationState.company_size
                    })}
                  >
                    <SelectValue placeholder="Select a company size" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={'Small'}>Small</SelectItem>
                    <SelectItem value={'Medium'}>Medium</SelectItem>
                    <SelectItem value={'Large'}>Large</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="pricing_tier">Pricing Tier</Label>
                <Select
                  disabled={isDisabledToUpdate}
                  onValueChange={(pricingTierId) => {
                    setSelectedPricingTierId(pricingTierId)
                  }}
                  value={selectedPricingTierId}
                >
                  <SelectTrigger
                    className={classNames({
                      'text-primary': selectedPricingTierId,
                      'text-placeholder': !selectedPricingTierId
                    })}
                  >
                    <SelectValue placeholder="Select a type" />
                  </SelectTrigger>

                  <SelectContent>
                    {pricingTiers?.map((pricingTier) => {
                      return (
                        <SelectItem
                          key={pricingTier.id}
                          value={pricingTier.id!}
                        >
                          {pricingTier?.name}
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="id">Elastic Search Cluster</Label>
                <Input
                  disabled
                  id="org_es_cluster"
                  value={elasticClusterForOrg ?? ''}
                />
              </div>
              <PricingTierAdvancedSettings
                defaultOpen={false}
                disabled={isDisabledToUpdate}
                loading={isLoadingAssignedPricingTier || isLoadingPricingTiers}
                onChange={(e, key) => {
                  setPricingTierState({
                    ...pricingTierState,
                    override_settings: {
                      ...pricingTierState?.override_settings,
                      [key]: e
                    }
                  })
                }}
                options={PricingTierSettings.filter(
                  (x) => x.key !== 'name'
                ).map((x) => ({
                  ...x,
                  value:
                    pricingTierState?.override_settings?.[x.key] ??
                    pricingTierState?.settings?.[x.key],
                  isShowGlobalSetting: pricingTierState?.override_settings?.[
                    x.key
                  ]
                    ? !isEqual(
                        pricingTierState?.settings?.[x.key],
                        pricingTierState?.override_settings?.[x.key]
                      )
                    : false,
                  globalSetting:
                    pricingTierState?.settings?.[x.key] ?? x.default
                }))}
              />
              <OrganizationDataPoints
                dataPoint={{
                  max_customer: dataPoint?.max_customer || 0,
                  actual_customer: dataPoint?.actual_customer || 0,
                  actual_users: dataPoint?.actual_users || 0,
                  max_users_last_month: dataPoint?.max_users_last_month || 0,
                  maxBillableUsersLastMonth: getMaxBillableUsersLastMonth(),
                  additionalPaidUsers: getAdditionalPaidUsersLastMonth()
                }}
                defaultOpen={false}
                loading={isDataPointsLoading || isOrganizationLoading}
              />
              <OrganizationPartnership
                defaultOpen={false}
                loading={isDataPointsLoading || isOrganizationLoading}
                partnershipData={{
                  actual_partner_users: getActualPartnerUsers() || 0,
                  max_partner_users: getMaxPartnerUsersLastMonth() || 0,
                  active_partnerships:
                    activePartners?.data?.results?.length || 0,
                  ecp_users: endCustomerPortalUsers?.data?.results?.length || 0,
                  ip_users: installerPortalUsers?.data?.results?.length || 0
                }}
              />
            </div>
          </div>
        </div>
      </TabsContent>
      <TabsContent value="features">
        <OrganizationFeatureSettings
          defaultOpen={true}
          disabled={isDisabledToUpdate}
          loading={
            isLoadingOrganizationSettings || isUpdatingOrganizationFeatures
          }
          onChange={(setting: {
            key: string
            value: { [key: string]: boolean }
          }) => {
            setIsUpdatingOrganizationFeatures(true)
            updateOrganizationFeatures.mutate(setting, {
              onSuccess: () => {
                setIsUpdatingOrganizationFeatures(false)
                toast({
                  title: 'Success',
                  description: 'Setting updated successfully'
                })
                slackService.sendMessage({
                  userEmail:
                    userData?.attributes['email'] ??
                    JSON.stringify(userData?.attributes),
                  featureName: setting.key,
                  payload: setting.value,
                  orgId: orgId!
                })
              },
              onError: (err) => {
                setIsUpdatingOrganizationFeatures(false)
                toast({
                  title: 'Error',
                  description: `Error while updating setting. ${err?.['message']}`
                })
              }
            })
          }}
          settings={{
            ...OrganizationFeatures,
            ...organizationSettings?.data
          }}
        />
      </TabsContent>
      <TabsContent value="blueprints">
        <BlueprintsList
          disabled={isDisabledToUpdate}
          organizationId={organization?.id}
        />
      </TabsContent>
      <TabsContent value="users">
        <UserList
          loginAsDisabled={isDisabledToUpdate}
          organizationId={organization?.id}
        />
      </TabsContent>
      <TabsContent value="roles">
        <RoleList
          disabled={isDisabledToUpdate}
          organizationId={organization?.id}
        />
      </TabsContent>
      <TabsContent value="partners">
        <PartnerList />
      </TabsContent>
      <TabsContent value="journey_analytics">
        {orgId && <JourneyAnalytics orgId={orgId} />}
      </TabsContent>
      <TabsContent value="usage-metrics">
        {orgId && <UsageMetrics orgId={orgId} />}
      </TabsContent>
      <OrganizationDeleteDialog
        onDelete={() => {
          queryClient.invalidateQueries({
            queryKey: [ORGANIZATION_QUERY_IDS.GET_ORGANIZATIONS_TO_CLEANUP]
          })
          updateOrganization.mutate(
            {
              id: orgId,
              tags: [...selectedTags, 'Marked for Cleanup']
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: [ORGANIZATION_QUERY_IDS.GET_ORGANIZATION, orgId]
                })
              }
            }
          )
        }}
        onOpenChange={setShowDeleteDialog}
        open={showDeleteDialog}
        organization={organization}
      />
    </Tabs>
  )
}

export default OrganizationDetail
