import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getUserClient, UserComponents } from '@/api/user-client'
import { ErrorResponse, IUser } from '@/models'

const userClient = getUserClient()

const USER_QUERY_IDS = {
  GET_USER_BY_ID: 'GET_USER_BY_ID',
  GET_USER_TAGS: 'GET_USER_TAGS',
  GET_USERS: 'GET_USERS',
  GET_ORGANIZATION_USER: 'GET_ORGANIZATION_USER',
  GET_USER_DATA_POINTS: 'GET_USER_DATA_POINTS'
}

export const useQueryGetOrgUsers: (
  orgId: string,
  opts?: UseQueryOptions<Awaited<ReturnType<typeof userClient.listUsersV2>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof userClient.listUsersV2>>>
> = (orgId, opts) => {
  return useQuery<Awaited<ReturnType<typeof userClient.listUsersV2>>>({
    queryKey: [USER_QUERY_IDS.GET_ORGANIZATION_USER, orgId],
    queryFn: () =>
      userClient.listUsersV2(
        { limit: 1000 },
        {},
        { headers: { 'x-ivy-org-id': orgId } }
      ),
    refetchOnWindowFocus: true,
    ...opts
  })
}

export const useQueryGetUserById: (
  userId: string,
  orgId: string,
  opts?: Partial<UseQueryOptions<IUser>>
) => ReturnType<typeof useQuery<IUser>> = (userId, orgId, opts) => {
  return useQuery<IUser>({
    queryKey: [USER_QUERY_IDS.GET_USER_BY_ID, userId, orgId],
    queryFn: () =>
      userClient
        .getUserV2({ id: userId }, {}, { headers: { 'x-ivy-org-id': orgId } })
        .then((res) => res.data),
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useQueryGetUserTags: (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof userClient.getUserTags>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof userClient.getUserTags>>>
> = (opts) => {
  return useQuery<Awaited<ReturnType<typeof userClient.getUserTags>>>({
    queryKey: [USER_QUERY_IDS.GET_USER_TAGS],
    queryFn: () => userClient.getUserTags(),
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

type GetUsersResult = {
  users: IUser[]
  last_evaluated_key: { pk: string; sk: string }
}

export const useQueryGetUsers: (
  opts?: UseQueryOptions<IUser[]>
) => ReturnType<typeof useQuery<IUser[]>> = (opts) => {
  const fetchAllUsers = async () => {
    const limit = '1000'
    let users: IUser[] = []
    let lastEvaluatedKey: { pk: string; sk: string } | undefined

    do {
      const { users: fetchedUsers, last_evaluated_key } = await fetchUsers(
        limit,
        JSON.stringify(lastEvaluatedKey)
      )

      users = users.concat(fetchedUsers)
      lastEvaluatedKey = last_evaluated_key

      if (!fetchedUsers.length) {
        break
      }
    } while (lastEvaluatedKey)

    return users
  }

  const fetchUsers = async (limit: string, startKey: string) => {
    return userClient
      .getUsers({ limit, ...(startKey && { start_key: startKey }) })
      .then((res) => res.data as unknown as GetUsersResult)
  }

  return useQuery<UserComponents.Schemas.UserV2[]>({
    queryKey: [USER_QUERY_IDS.GET_USERS],
    queryFn: fetchAllUsers,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useQueryGetUserDataPoints: (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof userClient.getDataPoints>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof userClient.getDataPoints>>>
> = (opts) => {
  return useQuery<Awaited<ReturnType<typeof userClient.getDataPoints>>>({
    queryKey: [USER_QUERY_IDS.GET_USER_DATA_POINTS],
    queryFn: () => userClient.getDataPoints(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useMutationUpdateUser: (
  orgId: string
) => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof userClient.updateUserV2>>,
    ErrorResponse,
    IUser,
    unknown
  >
> = (orgId) => {
  return useMutation<
    Awaited<ReturnType<typeof userClient.updateUserV2>>,
    ErrorResponse,
    IUser,
    unknown
  >({
    mutationFn: (user: IUser) => {
      return userClient.updateUserV2(user.id, user, {
        headers: {
          'x-ivy-org-id': orgId
        }
      })
    }
  })
}

export const useMutationUpdateUserEmail: (
  orgId: string
) => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof userClient.changeUserEmail>>,
    ErrorResponse,
    { oldEmail: string; newEmail: string },
    unknown
  >
> = (orgId) => {
  return useMutation<
    Awaited<ReturnType<typeof userClient.changeUserEmail>>,
    ErrorResponse,
    { oldEmail: string; newEmail: string },
    unknown
  >({
    mutationFn: ({
      oldEmail,
      newEmail
    }: {
      oldEmail: string
      newEmail: string
    }) => {
      return userClient.changeUserEmail(
        { username: oldEmail },
        { new_email: newEmail },
        {
          headers: {
            'x-ivy-org-id': orgId
          }
        }
      )
    }
  })
}
