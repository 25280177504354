import { AxiosInstance } from 'axios'
import { LocalStorage } from 'ttl-localstorage'

import { ADMIN_PORTAL_TOKEN_LS_KEY } from '@/providers'

interface AxiosClientOpts {
  baseURL?: string
  allowXOrgSharing?: boolean
  xEpilotOrgId?: string
}

export const configureClient = <ClientType extends AxiosInstance>(
  client: ClientType,
  opts: AxiosClientOpts = {}
) => {
  if (opts.baseURL) {
    client.defaults.baseURL = opts.baseURL
  }

  // add authorization header
  client.interceptors.request.use(async (config) => {
    const token = await LocalStorage.get(ADMIN_PORTAL_TOKEN_LS_KEY)

    if (!token) {
      throw new Error('UNAUTHORIZED')
    }

    if (config.headers) {
      config.headers.authorization = `Bearer ${token}`
    }

    return config
  })

  return client
}
