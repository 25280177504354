import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { API, Auth } from 'aws-amplify'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { LocalStorage } from 'ttl-localstorage'

import { Icons } from '@/components/ui/icons'

export const ADMIN_PORTAL_TOKEN_REFRESH_TTL = 900
export const ADMIN_PORTAL_TOKEN_LS_KEY = 'admin-portal-token'

type AuthContextType = {
  userData: {
    attributes: { [key: string]: string }
  } | null
  setUserData: React.Dispatch<
    React.SetStateAction<{
      attributes: {
        [key: string]: string
      }
    } | null>
  >
}

const initialState = {
  userData: null,
  setUserData: () => null
}

const AuthContext = createContext<AuthContextType>(initialState)

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [userData, setUserData] = useState<{
    attributes: { [key: string]: string }
  } | null>(null)
  const [loading, setLoading] = useState(true)

  const checkAuth = useCallback(async () => {
    if (!userData) {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const { token } = await API.get('internal-auth', '/auth', {})

        LocalStorage.put(
          ADMIN_PORTAL_TOKEN_LS_KEY,
          token,
          ADMIN_PORTAL_TOKEN_REFRESH_TTL
        )
        setUserData(user)
      } catch (error) {
        console.error(error)
      }
    }
    setLoading(false)
  }, [userData])

  useEffect(() => {
    checkAuth()
  }, [checkAuth])

  const value: AuthContextType = { userData, setUserData }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        enabled: Boolean(userData)
      }
    }
  })

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <div className="bg-background w-screen h-screen flex items-center justify-center">
          <Icons.spinner className="w-8 h-8 text-primary" />
        </div>
      ) : (
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      )}
    </AuthContext.Provider>
  )
}

const useAuth = (): AuthContextType => {
  return useContext<AuthContextType>(AuthContext)
}

export { AuthProvider, useAuth }
