import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import * as z from "zod";

import { Button } from "./ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "./ui/collapsible";
import { Icons } from "./ui/icons";
import { Label } from "./ui/label";

import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { FieldType } from "@/models";

type PricingTierAdvancedSettingsProps = {
  options: {
    key: string;
    label: string;
    type: number;
    fieldType: z.ZodSchema;
    value: any;
    isShowGlobalSetting?: boolean;
    globalSetting?: any;
  }[];
  onChange: (e: any, key: string) => void;
  loading?: boolean;
  defaultOpen?: boolean;
  disabled?: boolean;
};

const PricingTierAdvancedSettings = ({
  options,
  onChange,
  loading,
  defaultOpen = true,
  disabled = false,
}: PricingTierAdvancedSettingsProps) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Collapsible
      className="px-4 py-2 space-y-2 border border-border rounded-md"
      onOpenChange={(e) => !loading && setOpen(e)}
      open={open}
    >
      <CollapsibleTrigger asChild>
        <div className="flex items-center justify-between cursor-pointer">
          <h4 className="text-sm font-semibold text-primary">
            Pricing Tier Settings
          </h4>
          <Button
            disabled={loading}
            className="p-0 w-9"
            size="sm"
            type="button"
            variant="ghost"
          >
            {loading ? (
              <Icons.spinner className="w-4 h-4 mr-0" />
            ) : open ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
            <span className="sr-only">Toggle</span>
          </Button>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="space-y-2">
        <div className="max-h-[90vh] overflow-y-auto">
          <div className="space-y-4">
            {options.map((setting) => {
              if (setting.type === FieldType.SWITCH)
                return (
                  <div
                    className="space-y-2 flex flex-row items-center justify-between p-2 border border-border rounded-lg"
                    key={setting.key}
                  >
                    <Label htmlFor={setting.key}>
                      {setting.label}{" "}
                      {setting.isShowGlobalSetting && (
                        <span>
                          {" | "}
                          Global Setting:{" "}
                          {JSON.stringify(setting.globalSetting.enabled)}
                        </span>
                      )}
                    </Label>
                    <Switch
                      disabled={disabled}
                      checked={setting.value?.enabled ?? false}
                      id={setting.key}
                      onCheckedChange={(e) =>
                        onChange({ enabled: e }, setting.key)
                      }
                      style={{ marginTop: 0 }}
                    />
                  </div>
                );
              else if (setting.type === FieldType.NUMBER_FIELD)
                return (
                  <div className="space-y-2" key={setting.key}>
                    <Label htmlFor={setting.key}>
                      {setting.label}

                      {setting.isShowGlobalSetting && (
                        <span>
                          {" | "}
                          Global Setting: {setting.globalSetting.quota}
                        </span>
                      )}
                    </Label>
                    <Input
                      disabled={disabled}
                      id={setting.key}
                      min={0}
                      onChange={(e) =>
                        onChange(
                          { quota: parseInt(e.target.value) },
                          setting.key
                        )
                      }
                      placeholder="0"
                      type="number"
                      value={setting.value?.quota ?? 0}
                    />
                  </div>
                );
              else if (setting.type === FieldType.TEXT_FIELD)
                return (
                  <div className="space-y-2" key={setting.key}>
                    <Label htmlFor={setting.key}>{setting.label}</Label>
                    <Input
                      disabled={disabled}
                      id={setting.key}
                      onChange={(e) =>
                        onChange({ value: e.target.value }, setting.key)
                      }
                      value={setting.value?.value ?? 0}
                    />
                  </div>
                );
            })}
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default PricingTierAdvancedSettings;
