import { ChevronDownIcon } from 'lucide-react'

import { Badge } from '@/components/ui/badge'
import { Checkbox } from '@/components/ui/checkbox'
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header'
import { DataTableRowActions } from '@/components/ui/data-table-row-actions'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { IOrganization, Status, TableColumn } from '@/models'
import { unsensitiveFilter } from '@/utils'

export const OrganizationTableColumns: TableColumn<IOrganization>[] = [
  {
    id: 'select',
    size: 30,
    header: ({ table }) => (
      <div className="flex items-center space-x-2">
        <Checkbox
          aria-label="Select all in the page"
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="cursor-pointer">
              <ChevronDownIcon className="w-4 h-4" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="flex items-center w-56">
            <Checkbox
              aria-label="Select all"
              checked={table.getIsAllRowsSelected()}
              className="mr-2"
              id="select-all"
              onCheckedChange={(value) => table.toggleAllRowsSelected(!!value)}
            />
            <span>Select all items</span>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    ),
    cell: ({ row }) => (
      <Checkbox
        aria-label="Select row"
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
      />
    ),
    enableSorting: false,
    enableHiding: false,
    enableResizing: false,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'id',
    accessorKey: 'id',
    displayName: 'ID',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    enableResizing: false,
    filterFn: unsensitiveFilter,
    sortingFn: (a, b) => {
      const idA = a.original.id
      const idB = b.original.id

      if (!idA && !idB) {
        return 0
      } else if (!idA) {
        return 1
      } else if (!idB) {
        return -1
      }

      return parseInt(idA) - parseInt(idB)
    }
  },
  {
    id: 'name',
    accessorKey: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    displayName: 'Name',
    enableResizing: true,
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'type',
    accessorKey: 'type',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Type" />
    ),
    displayName: 'Type',
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'status',
    accessorFn: (row) => (row.status ? row.status : undefined),
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const status = Status.find(
        (status) => status.value === row.getValue('status')
      )

      if (!status) {
        return null
      }

      return (
        <div className="flex w-[100px] items-center">
          {status.icon && (
            <status.icon className="w-4 h-4 mr-2 text-muted-foreground" />
          )}
          <span>{status.label}</span>
        </div>
      )
    },
    sortingFn: (a, b) => {
      const statusA = a.original.status
      const statusB = b.original.status

      if (statusA === null && statusB === null) {
        return 0
      }

      if (statusA === null) {
        return -1
      }

      if (statusB === null) {
        return 1
      }

      if (statusA === 'Inactive' && statusB === 'Inactive') {
        return 0
      }

      if (statusA === 'Inactive') {
        return -1
      }

      if (statusB === 'Inactive') {
        return 1
      }

      if (statusA === 'Pending' && statusB === 'Pending') {
        return 0
      }

      if (statusA === 'Pending') {
        return -1
      }

      if (statusB === 'Pending') {
        return 1
      }

      return 0
    },
    displayName: 'Status',
    sortUndefined: -1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'source',
    accessorFn: (row) => row.is_unlicensed_org,
    displayName: 'Source',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Source" />
    ),
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'canary_status',
    accessorFn: (row) => (row.canary_status ? 'Enabled' : 'Disabled'),
    displayName: 'Canary Status',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Canary Status" />
    ),
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'release_candidate_status',
    accessorFn: (row) =>
      row.release_candidate_status ? 'Enabled' : 'Disabled',
    displayName: 'Release Candidate',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Release Candidate" />
    ),
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'severity_group',
    accessorKey: 'severity_group',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Severity Group" />
    ),
    displayName: 'Severity Group',
    enableResizing: true,
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'created_date',
    accessorKey: 'created_date',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Creation Date" />
    ),
    accessorFn: (row) => (row.created_date ? row.created_date : undefined),
    cell: ({ row }) => {
      const date = new Date(row.original.created_date)

      return date.toLocaleString()
    },
    sortingFn: (a, b) => {
      const dateA = new Date(a.original.created_date)
      const dateB = new Date(b.original.created_date)

      if (dateA === null && dateB === null) {
        return 0
      } else if (dateA === null) {
        return 1
      } else if (dateB === null) {
        return -1
      }

      return dateA.getTime() - dateB.getTime()
    },
    displayName: 'Creation Date',
    sortUndefined: -1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'symbol',
    accessorFn: (row) => (row.symbol ? row.symbol : undefined),
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Symbol" />
    ),
    displayName: 'Symbol',
    sortUndefined: -1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'customer_number',
    accessorKey: 'customer_number',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Customer Number" />
    ),
    displayName: 'Customer Number',
    sortUndefined: 1,
    sortingFn: (a, b) => {
      const customerNumberA = a.original.customer_number
      const customerNumberB = b.original.customer_number

      if (!customerNumberA && !customerNumberB) {
        return 0
      } else if (!customerNumberA) {
        return 1
      } else if (!customerNumberB) {
        return -1
      }

      return parseInt(customerNumberA) - parseInt(customerNumberB)
    },
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'new_pricing_tier',
    accessorKey: 'new_pricing_tier',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Pricing Tier" />
    ),
    displayName: 'Pricing Tier',
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'actual_users',
    accessorKey: 'actual_users',
    displayName: 'Actual User',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actual User" />
    ),
    sortUndefined: 1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'max_users_last_month',
    accessorKey: 'max_users_last_month',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Max User Last Month" />
    ),
    displayName: 'Max User Last Month',
    sortUndefined: 1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'max_billable_users_last_month',
    accessorKey: 'max_billable_users_last_month',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Max Billable User Last Month"
      />
    ),
    displayName: 'Max Billable User Last Month',
    sortUndefined: 1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'max_partner_users',
    accessorKey: 'max_partner_users',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Max Partner Users Last Month"
      />
    ),
    displayName: 'Max Partner Users Last Month',
    sortUndefined: 1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'additional_paid_users',
    accessorKey: 'additional_paid_users',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Additional Paid User" />
    ),
    displayName: 'Additional Paid User',
    sortUndefined: 1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'actual_customer',
    accessorKey: 'actual_customer',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actual Customer" />
    ),
    displayName: 'Actual Customer',
    sortUndefined: 1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'max_customer',
    accessorKey: 'max_customer',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Max Customer" />
    ),
    displayName: 'Max Customer',
    sortUndefined: 1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'email',
    accessorFn: (row) => (row.email ? row.email : undefined),
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
    displayName: 'Email',
    sortUndefined: -1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'tags',
    accessorKey: 'tags',
    displayName: 'Tags',
    filterFn: unsensitiveFilter,
    enableSorting: false,
    enableGlobalFilter: false,
    maxSize: 250,
    cell: ({ cell, row }) => {
      return (
        <div className="flex overflow-auto h-full -m-2 py-3">
          {((cell?.getValue() as undefined | string[]) ?? []).map((tag) => (
            <Badge
              className="mr-2 min-w-min whitespace-nowrap"
              key={tag + row.index}
              variant={'outline'}
            >
              {tag}
            </Badge>
          ))}
        </div>
      )
    },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Tags" />
    )
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      return <DataTableRowActions row={row} />
    },
    enableSorting: false,
    enableHiding: false,
    enableResizing: false,
    size: 30
  }
]
